// src/AuthPopup.js


// src/AuthPopup.js
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { auth } from './firebase';
import Banner from './Banner'; // Import the Banner component
import { useMediaQuery } from 'react-responsive';
import './MBetaSignupPopup.css';
import PrimaryButton from './PrimaryButton';
import { db } from './firebase'; // Import your Firestore instance
import { collection, addDoc } from 'firebase/firestore';
import ReactPixel from 'react-facebook-pixel';
import { v4 as uuidv4 } from 'uuid'; // Import UUID generator
import TertiaryButton from './TertiaryButton'




function MBetaSignupPopup({ isVisible, onClose }) {
  const popupRef = useRef(null);
  const [message, setMessage] = useState(''); // State for error messages
  const [showMessage, setShowMessage] = useState(false); // State to control banner visibility
  const navigate = useNavigate(); // Hook to programmatically navigate
  const location = useLocation();
  const langPrefix = location.pathname.split('/')[1] || 'en';
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const [submitted, setSubmitted] = useState(false); // State to control banner visibility


  const [name, setName] = useState('New User')
  const [city, setCity] = useState('Beta')
  const [email, setEmail] = useState('')

  const submitRegistration = async (e) => {

    let dotCount = 0;
    const dots = ['.', '..', '...'];
    const eventId = uuidv4();

    // Start a timer to cycle through the dots
    const interval = setInterval(() => {
      dotCount = (dotCount + 1) % dots.length; // Cycle between 0, 1, 2
      setMessage(`Submitting${dots[dotCount]}`);
    }, 500); // Update every 500ms

    try {
      const docRef = await addDoc(collection(db, 'betaSignups'), {
        email: email,
        timestamp: new Date(),
        name: name,
        city: city,
      });
      console.log('Document written with ID: ', docRef.id);
      setShowMessage(true)
    } catch (e) {
      console.error('Error adding document: ', e);
    }

    const response = await fetch('https://jointly-api-274980191481.europe-west1.run.app/api/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        first_name: name,
        email: email,
        event_id: eventId,
      }),
    });

    const data = await response.json();

    console.log(data)

    if (!response.ok || (data.status === 500)) {
      console.error('Failed to add subscriber to email sequence')
      clearInterval(interval);
      setMessage('');
      setName("New User")
      setCity("Beta")
      setEmail("")
    } else if (data.status === 201) {
      console.log('Already subscribed');
      clearInterval(interval);
      setMessage(langPrefix === 'nl' ? "Email in gebruik. Controleer uw email spam folder.." : 'Email already in use. Check your email spam folder..');
    } else if (data.status === 200) {
      console.log('Kit ok');

      clearInterval(interval);
      setSubmitted(true)
      window.fbq('track', 'CompleteRegistration', {}, {
        'eventID': eventId
      })

      setName("New User")
      setCity("Beta")
      setEmail("")
    }
  };



  const [initialHeight, setInitialHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      // Only update height if the keyboard is NOT open (screen shrinking).
      if (window.innerHeight > initialHeight) {
        setInitialHeight(window.innerHeight);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [initialHeight]);


  useEffect(() => {

    if (isVisible) {

      const handleEscape = (event) => {
        if (event.key === 'Escape') {
          onClose();
        }
      };
      document.addEventListener('keydown', handleEscape);

      return () => {
        document.removeEventListener('keydown', handleEscape);
      };
    }
  }, [isVisible, onClose]);

  const handleOutsideClick = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  return (
    <>
      {isVisible && (
        <div className="moverlay" onClick={handleOutsideClick}>
          <div className="mpopup"
            style={{ height: `${initialHeight * 0.8}px` }}
            ref={popupRef}>
            <button className="mclose-button" onClick={onClose}>X</button>
            <div className='mbeta-content-container'>


              {!submitted && (
                <>
                  <div className='mbeta-header-cont'>
                    <h3 className="mbeta-header-text">Launching February 27!</h3>
                  </div>

                  <div className='mbeta-desc-cont'>
                    {langPrefix === 'nl' ? <p className="mbeta-desc-text">Meld je nu aan om gratis toegang tot onze beta te verzekeren, join activiteiten en ontmoet nieuwe mensen!</p> : <p className="mbeta-desc-text">Sign up now to secure your access to our beta for free, to join activities and to start meeting new people.</p>}
                  </div>

                  {/* <div className="mbeta-namecity-cont">
                    <input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder={langPrefix === 'nl' ? "Voornaam" : "First Name"}
                      className='mbeta-name-form'
                    />
                    <input
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      placeholder={langPrefix === 'nl' ? "Gemeente" : "City"}
                      className='mbeta-name-form'
                    />
                  </div> */}

                  <div className="mbeta-email-cont">
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email*"
                      className='mbeta-email-form'
                    />
                  </div>
                  <div className="mbeta-disc-cont">
                    <p className='mbeta-disc-text'>No spam. Unsubscribe at any time.</p>
                  </div>

                  <div className="mbeta-button-cont">
                    <PrimaryButton text="Join Jointly" width="100%" height="100%" borderRadius={"8px"} fontSize={"4vw"} onLoginClick={submitRegistration} />
                  </div>
                  {showMessage && (
                    <div className="mbeta-message-cont">
                      <p className='mbeta-message-text'>{message}</p>
                    </div>)}
                </>
              )}

              {submitted && (
                <>
                  <div className='mbeta-subheader-cont'>
                    <h3 className="mbeta-subheader-text">{langPrefix === 'nl' ? "Welkom bij Jointly!" : "Welcome to Jointly!"}</h3>
                  </div>

                  <div className='mbeta-subdesc-cont'>
                    {langPrefix === 'nl' ? <p className="mbeta-subdesc-text">Download de app nu via onderstaande links. We sturen je ook een mailtje, dus check zeker ook je spamfolder.</p> : <p className="mbeta-subdesc-text">Download the app now via the links below. You will also receive an email soon so be sure to check your spam folder.</p>}
                  </div>

                  {/* <div className="mbeta-check-cont">
                    <img src='/assets/blue_check.png' alt="Registrated" className="mbeta-check" />
                  </div> */}

                  <div className='mbeta-android-button'>
                    <a href="https://play.google.com/store/apps/details?id=com.joinjointly.jointlyapp"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ display: "block", width: "100%", height: "100%", textDecoration: "none" }}>
                      <PrimaryButton text="Android" width="100%" height="100%" borderRadius={"12px"} fontSize={"4.5vw"} />
                    </a>
                  </div>

                  <div className='mbeta-ios-button'>
                    <a href="https://testflight.apple.com/join/tAmmtGYX"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ display: "block", width: "100%", height: "100%", textDecoration: "none" }}>
                      <PrimaryButton text="iOS/iPhone" width="100%" height="100%" borderRadius={"12px"} fontSize={"4.5vw"} />
                    </a>
                  </div>



                </>
              )
              }


            </div>
          </div>
        </div>)}
    </>
  );
}

export default MBetaSignupPopup;

