// src/AuthPopup.js


// src/AuthPopup.js
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { auth } from './firebase';
import Banner from './Banner'; // Import the Banner component
import { useMediaQuery } from 'react-responsive';
import './BetaSignupPopup.css';
import PrimaryButton from './PrimaryButton';
import { db } from './firebase'; // Import your Firestore instance
import { collection, addDoc } from 'firebase/firestore';
import ReactPixel from 'react-facebook-pixel';
import { v4 as uuidv4 } from 'uuid'; // Import UUID generator





function BetaSignupPopup({ isVisible, onClose }) {
  const popupRef = useRef(null);
  const [message, setMessage] = useState(''); // State for error messages
  const [showMessage, setShowMessage] = useState(false); // State to control banner visibility
  const navigate = useNavigate(); // Hook to programmatically navigate
  const location = useLocation();
  const langPrefix = location.pathname.split('/')[1] || 'en';
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const [submitted, setSubmitted] = useState(false); // State to control banner visibility


  const [name, setName] = useState('')
  const [city, setCity] = useState('')
  const [email, setEmail] = useState('')

  const submitRegistration = async (e) => {

    let dotCount = 0;
    const dots = ['.', '..', '...'];
    const eventId = uuidv4();

    // Start a timer to cycle through the dots
    const interval = setInterval(() => {
      dotCount = (dotCount + 1) % dots.length; // Cycle between 0, 1, 2
      setMessage(`Submitting${dots[dotCount]}`);
    }, 500); // Update every 500ms

    try {
      const docRef = await addDoc(collection(db, 'betaSignups'), {
        email: email,
        timestamp: new Date(),
        name: name,
        city: city,
      });
      console.log('Document written with ID: ', docRef.id);
      setShowMessage(true)
    } catch (e) {
      console.error('Error adding document: ', e);
    }

    const response = await fetch('https://jointly-api-274980191481.europe-west1.run.app/api/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        first_name: name,
        email: email,
        event_id: eventId,
      }),
    });

    const data = await response.json();

    console.log(data)

    if (!response.ok || (data.status === 500)) {
      console.error('Failed to add subscriber to email sequence')
      clearInterval(interval);
      setMessage('');
      setName("")
      setCity("")
      setEmail("")
    } else if (data.status === 201) {
      console.log('Already subscribed');
      clearInterval(interval);
      setMessage(langPrefix === 'nl'?"Email in gebruik. Controleer uw email spam folder..":'Email already in use. Check your email spam folder..');
    } else if (data.status === 200) {
      console.log('Kit ok');
      clearInterval(interval);
      setSubmitted(true)
      window.fbq('track', 'CompleteRegistration',{}, {
        'eventID': eventId
      })

      // ReactPixel.track('CompleteRegistration', {
      //   eventID: eventId,
      // },
      // {
      //   eventID: eventId,
      // });

      setName("")
      setCity("")
      setEmail("")
    }
  };


  useEffect(() => {

    if (isVisible) {

      const handleEscape = (event) => {
        if (event.key === 'Escape') {
          onClose();
        }
      };
      document.addEventListener('keydown', handleEscape);

      return () => {
        document.removeEventListener('keydown', handleEscape);
      };
    }
  }, [isVisible, onClose]);

  const handleOutsideClick = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  return (
    <>
      {isVisible && (<div className="overlay" onClick={handleOutsideClick}>
        <div className="popup" ref={popupRef}>
          <button className="close-button" onClick={onClose}>X</button>
          <div className='beta-content-container'>




            {!submitted && (
              <>
                <div className='beta-header-cont'>
                  <h3 className="beta-header-text">Launching February 27!</h3>
                </div>

                <div className='beta-desc-cont'>
                  {langPrefix === 'nl' ? <p className="beta-desc-text">Meld je nu aan om toegang tot onze bèta te verzekeren, ontmoet nieuwe mensen en wordt uitnodigingd voor onze gratis kick-off events!</p> : <p className="beta-desc-text">Sign up now to secure your access to our beta, to start meeting people, and to get invited to our kick-off events for free.</p>}
                </div>

                <div className="beta-namecity-cont">
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder={langPrefix === 'nl' ? "Voornaam" : "First Name"}
                    className='beta-name-form'
                  />
                  <input
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    placeholder={langPrefix === 'nl' ? "Gemeente" : "City"}
                    className='beta-name-form'
                  />
                </div>
                <div className="beta-email-cont">
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email*"
                    className='beta-email-form'
                  />
                </div>
                <div className="beta-disc-cont">
                  <p className='beta-disc-text'>No spam. Unsubscribe at any time.</p>
                </div>

                <div className="beta-button-cont">
                  <PrimaryButton text="Join Jointly" width="100%" height="100%" borderRadius={"8px"} onLoginClick={submitRegistration} />
                </div>
                {showMessage && (
                  <div className="beta-message-cont">
                    <p className='beta-message-text'>{message}</p>
                  </div>)}
              </>
            )
            }

            {submitted && (
              <>
                <div className='beta-subheader-cont'>
                  <h3 className="beta-subheader-text">{langPrefix === 'nl' ? "Welkom bij Jointly!" : "Welcome to Jointly!"}</h3>
                </div>

                <div className='beta-subdesc-cont'>
                  {langPrefix === 'nl' ? <p className="beta-subdesc-text">Bedankt voor het aanmelden. We hebben je info ontvangen en je ontvangt zodra een e-mail. Controleer ook je spammap.</p> : <p className="beta-subdesc-text">Thanks for joining our community. Your info has been submitted and you'll receive an email soon. Check your spam as well.</p>}
                </div>

                <div className="beta-check-cont">
                  <img src='/assets/blue_check.png' alt="Registrated" className="beta-check" />
                </div>
              </>
            )
            }

          </div>
        </div>
      </div>)}
    </>
  );
}

export default BetaSignupPopup;

